<template>
  <div>
    <modal :show="isModalShow" :showClose="false" v-if="itemToDelete">
      <template slot="header">
        <h5 class="modal-title">{{ $t(`confirm.delete.${itemToDelete}`) }}</h5>
      </template>
      <template slot="footer">
        <base-button type="secondary" @click="isModalShow = false">
          {{$t("cancel")}}</base-button>
        <base-button type="danger" @click="deleteItem(itemToDelete)">
          {{$t("delete")}}
        </base-button>
      </template>
    </modal>

    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="col d-block pb-2 d-sm-flex justify-content-sm-between mb-4">
              <div>
                <base-button type="cancel" @click="goToList()" >
                  {{ $t("goBack") }}
                </base-button>
              </div>
              <div>
                <base-button v-if="id != null && !isSpinnerShow" :disabled="areServices" type="danger" class="mr-2" @click="showModalDelete('serviceCategorie')">
                  {{ $tc("delete") }}
                </base-button>
                <base-button :disabled="isBtnDisabled" native-type="submit" form="newServiceCategoryForm" type="success">
                  {{ $t("save") }}
                </base-button>
              </div>
            </div>
            <div class="col d-flex justify-content-between">
              <div>
                <h1 v-if="id == null" class="card-title mb-0">
                  {{ $t(`routes.servicesCategoryNew`) }}
                </h1>
                <h1 v-if="id != null" class="mb-0">
                  {{ serviceCategories.name }}
                </h1>
              </div>
            </div>
          </template>

          <hr class="my-1" />

          <div class="warning-container">
            <span v-if="!isSpinnerShow && !areServices" class="d-block">
              <svg class="mx-1" width="18px" height="18px" stroke-width="1.5" viewBox="0 0 24 24" fill="none"  color="#000000">
                    <path d="M12 7v6M12 17.01l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                {{ $t('categoryNotDisplayed') }}
            </span>
          </div>

          <form id="newServiceCategoryForm" @change="disabledBtn(false)" @submit.prevent="manageItem()">
            <div v-if="!isSpinnerShow" class="col row py-4">
              <div class="col-12 col-md-5 col-lg-4">
                <base-input max="255" v-model="serviceCategories.name" :placeholder="$t('name')" :label="`${$t('name')}*`" required></base-input>
              </div>
              <div class="col-12 col-md-7 col-lg-8">
                <base-input :label="`${$t('description')}*`">
                  <textarea class="form-control" v-model="serviceCategories.description" :placeholder="$t('description')" required></textarea>
                </base-input>
              </div>
            </div>
            <Spinner v-else></Spinner>
          </form>
        </card>

        <card class="col">
          <label class="control-label mt-4 mt-md-0">
            {{$t("associatedServices")}}
            <svg v-if="!areServices" class="ml-1" width="18px" height="18px" stroke-width="1.5" viewBox="0 0 24 24" fill="none"  color="#000000">
              <path d="M12 7v6M12 17.01l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </label>

          <Spinner v-if="isSpinnerShow" class="py-4"></Spinner>
          <base-table :data="services">
            <template slot="columns">
              <th scope="col">{{ $t("name") }}</th>
              <th class="d-none d-sm-table-cell" scope="col">{{ $t("price") }}</th>
              <th class="d-none d-md-table-cell" scope="col"><span class="d-sm-none">{{ $t("timeMobile") }}</span> <span class="d-none d-sm-block">{{ $t("time") }}</span></th>
              <th scope="col"></th>
            </template>
            <template slot-scope="{ row }">
              <td>{{ row.name }}</td>
              <td class="d-none d-sm-table-cell">{{ row.price }}</td>
              <td class="d-none d-md-table-cell">{{ row.time }}</td>
              <td class="text-right">
                <base-button type="danger" class="deleteBtn" @click="showModalDelete('service', row.id)">
                  {{ $t("delete") }}
                </base-button>
              </td>
            </template>
          </base-table>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Card,
  BaseButton,
  BaseInput,
  BaseTable,
  Modal,
  Spinner,
} from "@/components/index";
import { mapActions, mapState } from "vuex";

export default {
  name: "ServiceCategorieForm",
  data: () => {
    return {
      id: null,
      idService: null,
      route: "/service-categories",
      isBtnDisabled: true,
      serviceCategories: {
        name: null,
        description: null,
      },
      services: [],
      isModalShow: false,
      isSpinnerShow: true,
      itemToDelete: "",
      confirmLeaveModal: false,
    };
  },
  computed: {
    areServices() {
      return this.services.length > 0;
    },
    ...mapState(["serviceCategorieControl", "hasChangeForm"]),
  },
  methods: {
    createItem() {
      let serviceCategorie = {
        name: this.serviceCategories.name,
        description: this.serviceCategories.description,
      };
      return serviceCategorie;
    },
    manageItem() {
      const serviceCategorie = this.createItem();
      if (this.id != null) {
        this.updateItem(serviceCategorie);
        return;
      }
      this.storeItem(serviceCategorie);
    },
    async storeItem(serviceCategorie) {
      this.disabledBtn(true);
      const response = await this.axios.post(this.route, serviceCategorie);
      if (response && response.data.status === "success") {
        this.goToList()
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    async getItem() {
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        const data = response.data.data.service_categories;
        this.serviceCategories = data;
        this.services = data?.services || [];
        
        this.disabledBtn(true);
        this.isSpinnerShow = false;
      }
    },
    async updateItem(serviceCategorie) {
      this.disabledBtn(true);
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.put(route, serviceCategorie);
      if (response && response.data.status === "success") {
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    async deleteItem(itemToDelete) {
      const route = itemToDelete === 'service'? `${this.itemToDelete}/${this.idService}` : `${this.route}/${this.id}`;
      let response = await this.axios.delete(route);

      this.isModalShow = false;
      if (response && response.data.status === "success") {
        if(itemToDelete === 'serviceCategorie') this.goToList();
        if(itemToDelete === 'service') this.refreshList();
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    disabledBtn(disabled) {
      this.isBtnDisabled = disabled;
      this.$store.dispatch('setHasChangeForm', !disabled )
    },
    showModalDelete(itemToDelete, idService = null) {
      this.isModalShow = true;
      if(idService) this.idService = idService;
      this.itemToDelete = itemToDelete;
    },
    refreshList() {
      this.services = this.services.filter( service => service.id !== this.idService );
    },
    goToList(){
      this.$router.push({name: 'servicesList'})
    },
    ...mapActions(["setHasChangeForm"])
  },
  beforeMount() {
    this.id = this.$route.params.id
    if (this.id != null) {
      this.getItem();
    } else {
      this.isSpinnerShow = false;
    }
  },
  components: {
    Card,
    BaseButton,
    BaseInput,
    BaseTable,
    Modal,
    Spinner,
  },
};
</script>

<style scoped>
.deleteServiceIcon {
  display: flex;
  margin: 0 auto;
}
.deleteBtn {
  padding: 11px 10px;
}
.warning-container {
  min-height: 22px;
}
@media (min-width: 576px) {
  .deleteBtn {
    padding: 11px 40px;
  }
}
</style>
